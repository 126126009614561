.app {
  width: 100vw;
  position: absolute;
  font-family: "Poppins", sans-serif;
}
.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.privacy{
  padding: 1rem;
}
.delete{
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left:200px;
}
.privacy p{
  display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.header-text {
  font-weight: 500;
  font-size: 50px;
  line-height: 68px;
}
.home-content {
  display: flex;
  width: 75%;
  justify-content: space-evenly;
}
.home-image-container {
  width: 25%;
  display: flex;
  align-items: center;
}
.footer-container {
  width: 100%;
  min-height: 400px;
  height: auto;
  background-color: #1a9e75;
  color: white;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
  padding-block: 10px;
  & a {
    text-decoration: none;
    color: white;
  }
}
.footer-title {
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  height: 17px;
  font-weight: 600;
  text-align: center;
  line-height: 15px;
}
.footer-content-container {
  display: flex;
  align-items: start;
  gap: 50px;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 30px;
}
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.about-header {
  background-image: url("../src/assets/aboutbg.png");
  width: 100%;
  height: 448px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}
.fastag-header {
  background-image: url("../src/assets/fastagbg.png");
  width: 100%;
  height: 448px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.bg-image {
  background-image: url("../src/assets/bg.png");
  width: 100%;

  height: 596px;
  background-size: cover;
}
.product-header {
  background-image: url("../src/assets/productbg.png");
  width: 100%;
  height: 448px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}
.about-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}
.person-video {
  position: absolute;
  width: 265px;
  height: 376px;
  border-radius: 15px;
}
.parking-container {
  width: 100%;
  display: flbootdx;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
}
.video-container {
  position: absolute;
}
.address-header {
  width: 330px;
  height: 48px;
  background-color: white;
  align-items: center;
  display: flex;
  border-radius: 15px;
  padding-inline: 25px;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
  & p {
    font-size: 18px;
  }
}
.address-container {
  display: grid;
  grid-template-columns: auto auto;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  width: 330px;
  margin-top: 10px;
  height: auto;
  gap: 5px;
  & p {
    font-size: 16px;
    font-weight: 500;
  }
}
.green-border {
  border-bottom: 1px solid #1a9e75;
  padding-bottom: 8px;
  border-width: "90%";
}
.career-header {
  background-image: url("./assets/careerbg.svg");
  width: 100%;
  height: 490px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  margin-bottom: 100px;
}
.contact-header {
  background-image: url("./assets/careerbg.svg");
  width: 100%;
  height: 370px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  margin-bottom: 100px;
}
.city-name {
  color: #606060;
  font-size: 24px;
  bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .fastagimg-container {
    width: 315px;
  }
  .parqwik-image {
    width: 800px;
    margin-bottom: 49px;
  }
  .header-text {
    font-size: 40px;
  }
  .home-content {
    flex-direction: column;
    align-items: center;
  }
  .home-image-container {
    width: 50%;
  }
}
@media screen and (max-width: 1000px) {
  .person-video {
    width: 200px;
    height: 282px;
  }
  .header-text {
    font-size: 40px;
  }
  .home-content {
    flex-direction: column;
    align-items: center;
  }
  .home-image-container {
    width: 90%;
  }
  .footer-content-container {
    display: grid;
    grid-template-columns: auto auto;
    width: 90%;
  }
  .parqwik-image {
    width: 500px;
    margin-bottom: 49px;
  }
}
@media screen and (max-width: 650px) {
  .fastagimg-container {
    width: 250px;
  }
  .footer-content-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 60%;
  }
  .parqwik-image {
    width: 300px;
    margin-bottom: 49px;
  }
}
@media screen and (max-width: 440px) {
  .footer-content-container {
    width: 90%;
  }
  .header-text {
    font-size: 28px;
    line-height: 34px;
  }
  .footer-title {
    width: 140px;
  }
  .parqwik-image {
    width: 300px;
    margin-bottom: 49px;
  }
  .person-video {
    width: 198px;
    height: 284px;
  }
  .address-header,
  .address-container {
    width: 280px;
  }
  .address-container {
    margin-bottom: 15px;
  }
}
